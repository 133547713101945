export const Theme = {
    base: 'vs',
    rules: [
        { token: 'keyword', foreground: '#0000FF' },
        { token: 'string.key', foreground: '#A31515' },
        { token: 'string.value', foreground: '#0451A5' },
        { token: 'number', foreground: '#098658' },
        { token: 'comment', foreground: '#008000'},

        { token: 'string', foreground: '#A31515' },
    ],
    colors: {
		"editor.foreground": "#000000",
	},
}